import { css, Global, useTheme } from '@emotion/react';
import { globalcss } from 'dw-apac-lwnz/theme';
import { injectGlobal } from '@emotion/css';

injectGlobal`@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&family=Oswald:wght@500&family=Roboto:wght@400;700;900&display=fallback');`;

const GlobalStyles = () => {
  const theme = useTheme();

  //NOTE: be VERY careful about setting global styles as this component is injected into
  //an existing page and hence global styles here will potentially affect all page styling
  const globalstyles = globalcss(theme);

  // * Add Css releated to embedded app only.
  // * styles applied here will be injected into
  // * an existing pages and hence embeddedStyles here will potentially have side affect all page styling
  const embeddedStyles = css`
    ${globalstyles}
    .vine-react-app {
      font-family: ${theme.fonts.familySecondary};
      font-size: ${theme.fonts.sizeRoot};
    }
    .sticky-header {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 10;
    }
    .enabled-vine-header {
      .aa-Panel {
        background: ${theme.colors.white};
        clip-path: inset(0 -100vmax);
        z-index: 999;
        box-shadow: 0 0 0 100vmax ${theme.colors.white};
        margin: 0;
        height: 100%;
        padding: 0 13px 67px;
        width: 100% !important;
        ${theme.breakpoints.lg} {
          height: auto;
          padding: 0;
        }
      }
      .aa-PanelLayout {
        max-height: 100%;
        flex-direction: column;
        ${theme.breakpoints.lg} {
          max-height: initial;
        }
      }
      .aa-SourceHeader {
        border-bottom: 0;
      }
    }
  `;

  return <Global styles={embeddedStyles} />;
};
export default GlobalStyles;
